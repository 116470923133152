import React from 'react';
import { graphql } from 'gatsby';
import DangerousTextbox from '../components/Text/dangerousTextbox';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import IframeNewsClient from '../components/Video/iframe-news-client';

// Previews
import { withPreview } from 'gatsby-source-prismic';

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-right: -15px;
  margin-left: -15px;

  & img {
    position: relative;
    width: ${(props) => props.imgWidth}%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 ${(props) => props.imgWidth}%;
    max-width: ${(props) => props.imgWidth}%;
  }
`;

const DownloadLink = styled.a`
  padding: ${(props) => props.padding};

  color: rgb(250, 60, 68);

  &:visited {
    color: rgb(250, 60, 68);
  }
`;

const IframeWrapper = styled.div`
  margin: 65px 0;
`;

const ClientPage = ({ data }) => {
  const article = data.prismicClientPage;

  const articleContent = article.data.body.map((article, index) => {
    if (article.slice_type === 'article_text') {
      return (
        <Col col={12} md={10} lg={8} key={article.prismicId}>
          <DangerousTextbox
            text={article.primary.text}
            padding={'0 0 65px 0'}
          />
        </Col>
      );
    } else if (article.slice_type === 'two_images') {
      const images = article.items.map((image, index) => {
        return (
          <img src={image.image1.url} alt={image.image1.alt} key={index} />
        );
      });

      return (
        <Col col={12} key={index}>
          <FlexBox imgWidth={100 / article.items.length}>{images}</FlexBox>
        </Col>
      );
    } else if (article.slice_type === 'section_title') {
      return (
        <Col col={12} md={10} lg={8} key={article.prismicId}>
          <DangerousTextbox
            text={article.primary.section_title}
            padding={'50px 0 25px 0'}
          />
        </Col>
      );
    } else if (article.slice_type === 'download_link') {
      return (
        <Col col={12} md={10} lg={8} key={article.prismicId}>
          <DownloadLink href={article.primary.pdf.url} download>
            <DangerousTextbox
              text={article.primary.link_title}
              padding={'0 0 50px 0'}
            />
          </DownloadLink>
        </Col>
      );
    } else if (article.slice_type === 'long_animation') {
      return (
        <Col col={12} key={article.id}>
          <IframeWrapper>
            <IframeNewsClient
              video={article.primary.animation.document.data.video.embed_url}
              thumbnail={
                article.primary.animation.document.data.video.thumbnail_url
              }
              width={article.primary.animation.document.data.video.width}
              height={article.primary.animation.document.data.video.height}
              vimeoId={article.primary.animation.document.data.video.video_id}
              thumbnail_url={
                article.primary.animation.document.data.video.thumbnail_url
              }
              provider_name={
                article.primary.animation.document.data.video.provider_name
              }
            />
          </IframeWrapper>
        </Col>
      );
    }
  });

  return (
    <React.Fragment>
      <Helmet
        title={data.prismicClientPage.data.title.text + ' – Everyone Agency'}
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />
      <Col col={12}>
        <Row justifyContent="center">{articleContent}</Row>
      </Col>
    </React.Fragment>
  );
};

export default withPreview(ClientPage);

export const ClientPageQuery = graphql`
  query SingleClientPage($uid: String!) {
    prismicClientPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicClientPageBodySectionTitle {
            slice_type
            primary {
              section_title {
                html
              }
            }
          }
          ... on PrismicClientPageBodyArticleText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicClientPageBodyTwoImages {
            slice_type
            items {
              image1 {
                alt
                url
              }
            }
          }
          ... on PrismicClientPageBodyDownloadLink {
            id
            slice_type
            primary {
              link_title {
                html
              }
              pdf {
                url
              }
            }
          }
          ... on PrismicClientPageBodyLongAnimation {
            id
            slice_type
            primary {
              animation {
                document {
                  ... on PrismicAnimation {
                    id
                    data {
                      video {
                        embed_url
                        thumbnail_url
                        width
                        height
                        provider_name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
