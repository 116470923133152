import * as React from 'react';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Col } from 'styled-bootstrap-grid';

// Pages
import Contact from './contact';
import Index from './index';
import VideoPage from './video';

// Templates
import Artist from '../templates/artist';
import ArtistAnimation from '../templates/artist-animation';
import ClientPage from '../templates/client-page';
import NewsList from '../templates/news-article';

const BodyLayout = {
  flex: '1',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 80px)',
};

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  & a:hover {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const ContactText = styled.div`
  color: rgb(250, 60, 68);

  p {
    font-size: 16px;
    line-height: 23px;
    margin: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 1em;
  }
`;

const PageNotFound = () => (
  <React.Fragment>
    <Helmet title={'Page Not Found – Everyone Agency'} />
    <Col col={12} style={BodyLayout}>
      <ContactContainer>
        <ContactText>
          Sorry we couldn't find what you were looking for...
        </ContactText>
      </ContactContainer>
    </Col>
  </React.Fragment>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(PageNotFound, {
  templateMap: {
    about: Contact,
    artist: Artist,
    animation: ArtistAnimation,
    client_page: ClientPage,
    homepage: Index,
    news_article: NewsList,
    video_page: VideoPage,
  },
});
