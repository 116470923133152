import React from 'react';

export const PrevArrow = () => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 22"
  >
    <path
      fill="#fa3c44"
      d="M20.14 1.22L2.5 11l17.64 9.78v-6.43H47.5v-6.7H20.14V1.22z"
    />
  </svg>
);

export const NextArrow = () => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 22"
  >
    <path
      fill="#fa3c44"
      d="M29.86 20.78L47.5 11 29.86 1.22v6.43H2.5v6.7h27.36v6.43z"
    />
  </svg>
);
