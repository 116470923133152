import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  padding: ${(props) => props.padding};

  & a {
    color: rgb(250, 60, 68);
  }

  @media (max-width: 767px) {
    padding: 25px 0;
  }
`;

const DangerousTextbox = ({ text, padding }) => {
  return (
    <Text
      padding={padding}
      dangerouslySetInnerHTML={{
        __html: text.html,
      }}
    />
  );
};

export default DangerousTextbox;
