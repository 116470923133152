import React from 'react';
import { graphql, Link } from 'gatsby';

// Previews
import { withPreview } from 'gatsby-source-prismic';

import NewsTitle from '../components/Text/newsTitle';
import DangerousTextbox from '../components/Text/dangerousTextbox';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import IframeNewsClient from '../components/Video/iframe-news-client';

// Icons
import { PrevArrow, NextArrow } from '../components/Icons/news-article-icons';

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-right: -15px;
  margin-left: -15px;

  & div {
    position: relative;
    width: ${(props) => props.imgWidth}%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 ${(props) => props.imgWidth}%;
    max-width: ${(props) => props.imgWidth}%;
  }

  margin-bottom: 30px;
`;

const Caption = styled.p`
  margin: 0;

  font-size: 14px;
`;

const NewsContainer = styled.div`
  padding: 0 50px;

  @media (max-width: 920px) {
    padding: 0;
  }
`;

const ArticleLinks = styled.div`
  margin: 60px 0 0 0;
  text-align: center;

  & a {
    margin: 0 15px;

    & svg {
      width: 36px;
      height: auto;
    }
  }
`;

const NewsList = ({ data, pageContext }) => {
  const article = data.prismicNewsArticle;

  const articleContent = article.data.body.map((article, index) => {
    if (article.slice_type === 'article_text') {
      return (
        <Col col={12} md={10} lg={8} key={article.id}>
          <DangerousTextbox text={article.primary.text} padding={'50px 0'} />
        </Col>
      );
    } else if (article.slice_type === 'two_images') {
      const images = article.items.map((image, index) => {
        return (
          <div key={index}>
            {image.link !== null ? (
              <a
                href={image.link.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  srcSet={`${image.image1.url}&w=800 1x,
              ${image.image1.url}&w=1200&fit=max&dpr=2 2x,
              ${image.image1.url}&w=2000&fit=max&dpr=3 3x`}
                  src={`${image.image1.url}&w=1200`}
                  key={index}
                  alt={image.image1.alt}
                />
                <Caption>{image.image1.alt}</Caption>
              </a>
            ) : (
              <>
                <img
                  srcSet={`${image.image1.url}&w=800 1x,
              ${image.image1.url}&w=1200&fit=max&dpr=2 2x,
              ${image.image1.url}&w=2000&fit=max&dpr=3 3x`}
                  src={`${image.image1.url}&w=1200`}
                  key={index}
                  alt={image.image1.alt}
                />
                <Caption>{image.image1.alt}</Caption>
              </>
            )}
          </div>
        );
      });
      return (
        <Col col={12} key={index}>
          <FlexBox imgWidth={100 / article.items.length}>{images}</FlexBox>
        </Col>
      );
    } else if (article.slice_type === 'short_video') {
      const videos = article.items.map((video, index) => (
        <div key={index}>
          <video src={video.short_video.url} muted autoPlay loop playsInline />
          <Caption>{video.caption}</Caption>
        </div>
      ));

      return (
        <Col col={12} key={index}>
          <FlexBox imgWidth={100 / article.items.length}>{videos}</FlexBox>
        </Col>
      );
    } else if (article.slice_type === 'long_animation') {
      return (
        <Col col={12} key={article.id}>
          <IframeNewsClient
            video={article.primary.video.embed_url}
            thumbnail={article.primary.video.thumbnail_url}
            width={article.primary.video.width}
            height={article.primary.video.height}
            vimeoId={article.id}
            thumbnail_url={article.primary.video.thumbnail_url}
            provider_name={article.primary.video.provider_name}
          />
        </Col>
      );
    }
  });

  return (
    <NewsContainer>
      <Helmet
        title={article.data.title.text + ' – Everyone Agency'}
        meta={[
          {
            name: 'description',
            content: `${article.data.excerpt.text}`,
          },
          {
            name: 'og:image',
            content: `${article.data.image.url}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${article.data.image.url}`,
          },
          {
            name: 'og:description',
            content: `${article.data.excerpt.text}`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `${article.data.title.text}`,
          },
          {
            name: 'twitter:description',
            content: `${article.data.excerpt.text}`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${article.data.image.url}`,
          },
        ]}
      />
      <Col col={12}>
        <Row justifyContent="center">
          <NewsTitle text={article.data.title.text} padding={'100px 0'} />
        </Row>

        <Row>
          <Col col={12}>
            <img src={article.data.image.url} alt={article.data.image.alt} />
          </Col>
        </Row>

        <Row justifyContent="center">{articleContent}</Row>

        <Row>
          <Col col={12}>
            <ArticleLinks>
              {pageContext.prev !== undefined && (
                <Link to={`/news/${pageContext.prev.uid}`}>
                  <PrevArrow />
                </Link>
              )}

              {pageContext.next !== undefined && (
                <Link to={`/news/${pageContext.next.uid}`}>
                  <NextArrow />
                </Link>
              )}
            </ArticleLinks>
          </Col>
        </Row>
      </Col>
    </NewsContainer>
  );
  // }
};

export default withPreview(NewsList);

export const newsPageQuery = graphql`
  query SingleNewsArticle($uid: String!) {
    prismicNewsArticle(uid: { eq: $uid }) {
      uid
      data {
        title {
          html
          text
        }
        excerpt {
          text
        }
        image {
          alt
          url
        }
        body {
          ... on PrismicNewsArticleBodyShortVideo {
            slice_type
            id
            items {
              short_video {
                url
              }
              caption
            }
          }
          ... on PrismicNewsArticleBodyArticleText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicNewsArticleBodyLongAnimation {
            slice_type
            id
            primary {
              video {
                embed_url
                thumbnail_url
                width
                height
                provider_name
              }
            }
          }
          ... on PrismicNewsArticleBodyTwoImages {
            slice_type
            id
            items {
              link {
                url
              }
              image1 {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;
