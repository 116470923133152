import React from 'react';
import { graphql } from 'gatsby';

// Previews
import { withPreview } from 'gatsby-source-prismic';

import InnerIndex from '../components/Homepage/inner-index';

const Index = ({ location, data }) => {
  return (
    <InnerIndex data={data} location={location} activeFilter={`Illustration`} />
  );
};
export default withPreview(Index);

export const query = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        logo {
          url
        }
        title {
          text
        }
        text {
          html
          text
        }
        thumbnail_text {
          html
        }
        video_embed {
          embed_url
        }
        video_embed_thumbnail {
          fluid(maxWidth: 1200) {
            srcSetWebp
            srcWebp
          }
        }
        image {
          fluid(maxWidth: 1200) {
            srcSetWebp
            srcWebp
          }
        }
      }
    }
    allPrismicArtist(
      sort: { fields: data___name___text, order: ASC }
      filter: { tags: { ne: "Together" } }
    ) {
      edges {
        node {
          tags
          prismicId
          uid
          data {
            name {
              text
            }
            homepage_images {
              image {
                fluid(maxWidth: 400) {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
